import React, { useState } from 'react';

const App = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [locationSent, setLocationSent] = useState(false);

  const handleCallPhone = () => {
    window.location.href = 'tel:+447860538199';
  };

  const handleSendLocation = () => {
    if (locationSent) {
      return; // Do nothing if the location has already been sent
    }

    if (!navigator.geolocation) {
      alert('Geolocation is not supported by your browser');
      return;
    }

    // Check if the user has granted permission to access their location
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        getAndSendLocation();
      } else if (result.state === 'prompt') {
        navigator.geolocation.getCurrentPosition(
          getAndSendLocation,
          (error) => {
            console.error('Error getting location:', error);
            alert('Error getting location. Please grant permission to access your location.');
          },
          {
            enableHighAccuracy: true // Trigger permission prompt on most browsers
          }
        );
      } else {
        alert('Please grant permission to access your location.');
      }
    });
  };

  const getAndSendLocation = (position) => {
    if (!position || !position.coords) {
      console.error('Error getting location:', position.message || 'Unknown error');
      alert('Error getting location. Please try again.');
      setIsLoading(false);
      return;
    }

    // Set loading to true when the location fetching starts
    setIsLoading(true);

    const { latitude, longitude } = position.coords;
    const formId = 'r0xtZpu0o';
    const formUrl = `https://submit-form.com/${formId}`;

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

    const formData = {
      _subject: 'CallAmmar Response',
      email: 'hello@callammar.com',
      message: `${googleMapsUrl}`
    };

    fetch(formUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        alert('Location sent successfully');
        setLocationSent(true); // Set locationSent to true after successful submission
      })
      .catch((error) => {
        console.error('Error sending location:', error);
        alert('Error sending location.');
      })
      .finally(() => {
        // Set loading to false when the fetch is complete
        setIsLoading(false);
      });
  };

  const handleSendSMS = () => {
    // Implement send SMS functionality or redirect to an `sms:` protocol URL
    window.location.href = 'sms:+447860538199';
  };

  return (
    <div className="app bg-gray-100 h-screen flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-blue-600 font-semibold text-4xl mb-10 text-center">Call Ammar</h1>
        <p className="text-gray-700 text-md text-center mr-10 ml-10 mb-10">
          Quickly contact Ammar in the event of an emergency.
        </p>
        <button
          onClick={handleCallPhone}
          className="bg-green-500 text-white py-2 px-4 rounded mb-3 w-full"
        >
          Call Phone
        </button>
        <button
          onClick={handleSendLocation}
          disabled={isLoading || locationSent}
          className={`py-2 px-4 rounded mb-3 w-full ${
            isLoading || locationSent
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-green-500 text-white'
          }`}
        >
          {isLoading ? 'Loading...' : locationSent ? 'Location has already been sent' : 'Send Location'}
        </button>
        <button
          onClick={handleSendSMS}
          className="bg-green-500 text-white py-2 px-4 rounded w-full"
        >
          Send SMS
        </button>
      </div>
    </div>
  );
};

export default App;